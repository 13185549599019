.inside_step p{
    color:#0F141A;
    font-size:16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.box_recommandons{
    max-width:591px;
    width: 100%;
    height: 80px;
    background:#EFF2F7;
    border-radius:5px;
    overflow: hidden;
    margin:0;
    text-align: left;
}
.box_recommandons .icon{
    width:66px;
    height: 80px;
    float: left;
    background:#E90657 url('../img/icon_espace.svg') no-repeat center center;
}
.box_recommandons .txt_link{
    height:66px;
    float: none;
    padding-top: 15px;
    padding-left: 60px;
}
.box_recommandons .txt_link p{
    font-size:12px;
    color:#667790;
    padding-left:15px;
    line-height: 15px;
}
.box_recommandons .txt_link a,.box_recommandons .txt_link a:visited{
    font-size:12px;
    color:#E90657;
    padding-left:15px;
    background:url('../img/pink_right.png') no-repeat right 11px;
    width:102px;
    display: block;
    line-height: 31px;
    height: 30px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
input[type="radio"]{
    visibility: hidden;
}
.radio_1{
    max-width:280px;
    width: 100%;
    height:90px;
    line-height:90px;
    text-align: center;
    font-size:16px;
    color:#717F96;
    border:1px solid #717F96;
    background:#ffffff;
    border-radius:45px;
    padding:0 30px;
    margin: 10px 35px 10px 0;
    transition:all 300ms ease-in-out;
    cursor: pointer;
    position: relative;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.radio_1.oui,.radio_1.non {
    border: 1px solid #0555FA;
    background:#EFF2F7;
    text-align: left;
    color:#0555FA;
    transition:all 300ms ease-in-out;
}
.radio_3{
    max-width:280px;
    width: 100%;
    height:90px;
    line-height:90px;
    text-align: center;
    font-size:16px;
    color:#717F96;
    border:1px solid #717F96;
    background:#ffffff;
    border-radius:45px;
    padding:0 30px;
    margin: 10px 35px 10px 0;
    transition:all 300ms ease-in-out;
    cursor: pointer;
    position: relative;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.radio_3.oui,.radio_3.non {
    border: 1px solid #0555FA;
    background:#EFF2F7;
    color:#0555FA;
    text-align: left;
    transition:all 300ms ease-in-out;
}
.radio_4{
    font-size:16px;
    color:#667790;
    cursor: pointer;
    border-bottom:1px solid #0555FA;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.radio_4.aucun{
    color:#0555FA;
}
.radio_1 i,.radio_3 i{
    position: absolute;
    right:30px;
    top:34px;
    font-weight: lighter;
    font-size:18px;
}
.radio_2{
    max-width:280px;
    width: 100%;
    height:90px;
    line-height:22px;
    text-align: center;
    font-size:16px;
    color:#717F96;
    border:1px solid #717F96;
    background:#ffffff;
    border-radius:45px;
    padding:0 30px;
    margin: 10px 35px 10px 0;
    transition:all 300ms ease-in-out;
    cursor: pointer;
    position: relative;
    padding-top:20px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.radio_2 span{
    display: block;
}
.radio_2.active {
    border: 1px solid #0555FA;
    background:#EFF2F7;
    color:#0555FA;
    transition:all 300ms ease-in-out;
}
.radio_2.active span{
    color:#0555FA;
}
.radio_2 img{
    display: inline-block;
}
.radio_5{
    font-size:16px;
    color:#667790;
    cursor: pointer;
    background:url('../img/radio_5.png') no-repeat left center;
    padding-left: 36px;
    height: 42px;
    line-height: 41px;
    margin-right: 15px;
    transition:all ease-in-out 250ms;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.radio-content{
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 591px;
    width: 100%;
}
.radio-content:before{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 64%;
    width: calc(100% - 25px);
    height: 1px;
    background: #C2C8D2;
    z-index: 1;
    transform: translate(0%, -50%);
    transition: 2s ease-in-out;
}
.radio-content:after{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 64%;
    width: 0;
    height: 1px;
    background: #0555FA;
    z-index: 1;
    transform: translate(0%, -50%);
    transition: .5s ease-in-out;
}
.radio-content.one:after{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 64%;
    width: 0%;
    height: 1px;
    background: #0555FA;
    z-index: 1;
    transform: translate(0%, -50%);
    transition: .5s ease-in-out;
}
.radio-content.two:after{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 64%;
    width: calc(35% - 25px);
    height: 1px;
    background: #0555FA;
    z-index: 1;
    transform: translate(0%, -50%);
    transition: .5s ease-in-out;
}
.radio-content.three:after{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 64%;
    width: calc(65% - 25px);
    height: 1px;
    background: #0555FA;
    z-index: 1;
    transform: translate(0%, -50%);
    transition: .5s ease-in-out;
}
.radio-content.four:after{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 64%;
    width: calc(100% - 25px);
    height: 1px;
    background: #0555FA;
    z-index: 1;
    transform: translate(0%, -50%);
    transition: .5s ease-in-out;
}
.radio-site-container{
    text-align: center;
    font-size:16px;
    z-index: 2;
}
.radio-site-container p{
    font-size: 15px;
    color:#667790;
}
.radio_site{
    z-index: 2;
    font-size:16px;
    color:#667790;
    cursor: pointer;
    background:url('../img/radio_site.svg') no-repeat left center;
    width: 23px;
    height: 23px;
    line-height: 23px;
    transition:all ease-in-out 250ms;
}
.radio-site-container.active p{
    color:#0555FA;
}
.radio-site-container.active .radio_site{
    color:#0555FA;
    transition:all ease-in-out 250ms;
    background:url('../img/radio_site_active.svg') no-repeat left center;
}
.radio_5.coproprie,.radio_5.syndic,
.radio_5.estimation,.radio_5.superficie,.radio_5.active{
    color:#0555FA;
    transition:all ease-in-out 250ms;
    background:url('../img/radio_5_active.png') no-repeat left center;
}
.simple_input{
    max-width:280px;
    width: 100%;
    height:50px;
    font-size:16px;
    color:#667790;
    padding:5px 10px;
    border: 1px solid #C2C8D2;
    border-radius:5px;
    box-shadow: 0 0 6px -2px #cccccc;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.postal_wrapper h6{
    display: inline-block;
}
.simple_input.txt_c_p{
    background:#ffffff url('../img/icon_code_postal.svg') no-repeat 15px center;
    padding: 5px 10px 5px 35px;
    /*display: block;*/
    display: inline-block;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    width: 175px;
    margin-left: 15px;
}
@media screen and (max-width: 576px){
    .simple_input.txt_c_p{
        margin-left: 0;
    }
}
.simple_label{
    display: block;
    font-size:16px;
    color:#0F141A;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.list_CP{
    width:85px;
    height:37px;
    border:1px solid #0555FA;
    color:#0555FA;
    position: relative;
    float:left;
    margin-right:12px;
    display: block;
    padding:10px;
    font-size:11px;
    border-radius: 5px;
    margin-top: 10px;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
}
.list_CP i{
    cursor: pointer;
    color: #0555FA;
    position: absolute;
    font-size: 16px;
    right: 10px;
    top: 9px;
    font-weight: lighter;
}
.btn_next_continue,.btn_next_continue:visited,.btn_next_continue:active{
    float:right;
    width:165px;
    height:56px;
    line-height: 50px;
    color:#ffffff;
    background:#E90657 url('../img/to_right.png') no-repeat 90% center;
    padding:0 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: left;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    position: relative;
    z-index: 10;
    text-align: center; /* added for align text center */
}

.btn_devis{
    outline: none !important;
    box-shadow: none;
    float:right;
    width:220px;
    height:56px;
    color:#ffffff;
    background:#0555FA url('../img/to_right.png') no-repeat 90% center;
    padding:0 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: left;
    font-family: "RoobertEnel-Regular";
    position: relative;
    z-index: 10;
    margin-right: 20px;
}
.btn_devis:disabled{
    opacity: 0.5;
    cursor: not-allowed;
}
.add_coproprietie{
    display: block;
    margin:15px 0 0 15px;
    font-size:16px;
    color:#667790;
    cursor: pointer;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.add_coproprietie i{
    color:#0555FA;
    font-weight: lighter;
    height:26px;
    width: 26px;
    border-radius: 50%;
    border:1px solid #667790;
    text-align: center;
    line-height: 24px;
    margin-left: 8px;
}
i.remove_copr{
    color:#0555FA;
    height:26px;
    width: 26px;
    border-radius: 50%;
    border:1px solid #667790;
    text-align: center;
    line-height: 15px;
    margin-left: 8px;
    cursor: pointer;
}
.previous{
    font-size:16px;
    color:#667790;
    cursor: pointer;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.previous i{
    margin-right: 10px;
}
.previous:hover{
    color:#0555FA;
}
.list_box_container{
    width:fit-content;
    height:50px;
    border:1px solid #C2C8D2;
    border-radius:5px;
}
.list_box_container .list_box_options{
    width:fit-content;
    height:50px;
    overflow: hidden;
    float:left;
    width: 100%;
    max-width: 400px;
}
.list_box_container .list_box_options.show_list{
    overflow: visible;
    height: fit-content;
}
.list_box_container .list_box_options p{
    min-height:50px;
    /*line-height: 50px;*/
    line-height: 40px;
    display: block;
    color:#C2C8D2;
    font-size:16px;
    padding:0 0 0 15px;
    cursor: pointer;
}
.list_box_container .list_box_options p:hover{
    color:#0555FA;
}
.list_box_container .trigger_list_box{
    width:48px;
    height:48px;
    text-align: center;
    line-height:48px;
    overflow: hidden;
    float:left;
    border-left:1px solid #C2C8D2;
    cursor: pointer;
}
.list_box_container .trigger_list_box i{
    color:#0555FA;
    font-size:18px;
    font-weight: lighter;
}
.add_etab_to_list{
    cursor: pointer;
    font-size:16px;
    color:#667790!important;
    display: block;
    float: left;
    clear: left;
    /*width: 100%;*/
}
.add_etab_to_list i{
    color:#0555FA;
    font-weight: lighter;
    height:26px;
    width: 26px;
    border-radius: 50%;
    border:1px solid #667790;
    text-align: center;
    line-height: 24px;
    margin-left: 8px;
}
.choosen_etab{
    float: right;
}
.choosen_etab p{
    cursor: pointer;
    color:#0555FA;
    font-size:16px;
    margin-bottom: 8px;
    width:100%;
}
.choosen_etab p i{
    color:#0555FA;
    font-weight: lighter;
    height:26px;
    width: 26px;
    border-radius: 50%;
    border:1px solid #C2C8D2;
    text-align: center;
    line-height: 24px;
    margin-left: 8px;
    float: right;
}
.add_all,.delete_all{
    cursor: pointer;
    font-size:13px!important;
    color:#717F96!important;
    padding-left: 7px;
    border-bottom: 1px solid #717F96;
}
.radio_6{
    line-height:19px!important;
}
.radio_6 span{
    display: inline-block;
}

/* MOBILE CSS */
@media screen and (max-width: 576px){
    .postal_wrapper{
        display: flex;
    }
    .box_recommandons{
        overflow: visible;
        margin-top: 28px;
        height: 100px;
    }
    .box_recommandons .icon{
        height: 100px;
    }
    .box_recommandons .txt_link{
        float: right;
        padding: 9px 0;
        width: calc(100% - 66px);
    }
    .box_recommandons .txt_link p{
        line-height: 16px;
        margin-top: 0;
        padding-left: 10px;
    }
    .box_recommandons .txt_link a, .box_recommandons .txt_link a:visited{
        padding-left: 10px;
    }
    .previous{
        text-align: center;
        display: block;
        margin-top: 35px;
    }
    .btn_next_continue,.btn_next_continue:visited,.btn_next_continue:active{
        max-width: 165px;
        width: 100%;
        display: block;
        margin: 0 auto;
        float: none;
    }
    .btn_devis,.btn_devis:visited,.btn_devis:active{
        display: block;
        margin: 15px auto 0;
        float: none;
    }
    .simple_input.promo_input{
        width: calc(100% - 135px);
    }
}

@media screen and (max-width: 360px){
    .box_recommandons{
        height: 100px;
    }
    .box_recommandons .icon{
        height: 100px;
    }
}
/* SMALL MOBILE CSS */
@media screen and (max-width: 320px){
    .radio_5{
        font-size: 14px;
    }
}

/* Consommation css */

.checkout{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height:50px;
    font-size:16px;
    color:#717F96;
    border:1px solid #717F96;
    background:#ffffff;
    border-radius:45px;
    padding:0 30px;
    margin: 10px 35px 10px 0;
    transition:all 300ms ease-in-out;
    cursor: pointer;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.checkout.oui{
    border: 1px solid #0555FA;
    background:#EFF2F7;
    color:#0555FA;
    transition:all 300ms ease-in-out;
}
.checkout2{
    font-size:16px;
    color:#667790;
    cursor: pointer;
    background:url('../img/radio_5.png') no-repeat left center;
    padding-left: 50px;
    height: 42px;
    line-height: 41px;
    transition:all ease-in-out 250ms;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.checkout2.oui{
    color:#0555FA;
    transition:all ease-in-out 250ms;
    background:url('../img/radio_5_active.png') no-repeat left center;
}
.simple_input_2{
    max-width:280px;
    width: 100%;
    height:50px;
    font-size:16px;
    color:#667790;
    padding:5px 10px;
    border: 1px solid #C2C8D2;
    border-radius:5px;
    box-shadow: 0 0 6px -2px #cccccc;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    background-color: #ffffff;
}
.simple_input_2::-webkit-outer-spin-button,
.simple_input_2::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 400px){
    .checkout{
        padding:0 5px;
        margin: 10px 5px 10px 0;
    }
}
.client-cp{
    float: none !important;
    display: inline-block;
}
@keyframes bright-color {
    0%{
        box-shadow: 0px 0px 10px #EDF3FE;
    }
    30%{
        box-shadow: 0px 0px 10px #0557fa;
    }
    50%{
        box-shadow: 0px 0px 5px #EDF3FE;
    }
    60%{
        box-shadow: 0px 0px 10px #0557fa;
    }
    100%{
        box-shadow: 0px 0px 00px #EDF3FE;
    }
}
.animate-bright-color{
    animation: bright-color 1s;
}
.devis-container{
    padding: 10px 40px;
}
.devis-container .devis-wrapper .title-devis h3{
    padding: 0;
    margin: 0;
    color: #000000;
    font-size: 30px;
    font-family: "RoobertEnel-Bold";
    text-align: center;
}
.devis-container .devis-wrapper .title-devis p{
    padding: 0;
    margin: 0;
    color: #000000;
    font-size: 16px;
    font-family: "RoobertEnel-Regular";
    text-align: center;
}
.devis-container .devis-wrapper .details-devis{
    margin-top: 50px;
    padding: 10px;
    background-color: #EDF3FE;
    border-radius: 10px;
    border: 1px solid #EAEAEC;
    display: flex;
    align-items: center;
}
.devis-container .devis-wrapper .details-devis .left-devis{
    flex: 200px;
}
.devis-container .devis-wrapper .details-devis .right-devis{
    flex: calc(100% - 200px);
    background: #fff;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}
.devis-container .devis-wrapper .details-devis .left-devis h3{
    padding: 0;
    margin: 0;
    color: #0555FA;
    font-size: 19px;
    font-family: "RoobertEnel-Bold";
}
.devis-container .devis-wrapper .details-devis .left-devis p{
    padding: 0;
    margin: 0;
    color: #0555FA;
    font-size:14px;
    font-family: "georgiaItalic";
}
.devis-container .devis-wrapper .details-devis .right-devis .duree-devis{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 150px;
}
.devis-container .devis-wrapper .details-devis .right-devis .estimation-devis{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 150px);
}
.devis-container .devis-wrapper .details-devis .right-devis .estimation-devis .blocs{
    display: flex;
    justify-content: space-between
}
.devis-container .devis-wrapper .details-devis .right-devis .duree-devis h4{
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #404040;
    font-family: "RoobertEnel-Regular";
}
.devis-container .devis-wrapper .details-devis .right-devis .duree-devis p{
    text-align: center;
    padding: 0;
    margin: 0;
    margin-top: 15px;
}
.devis-container .devis-wrapper .details-devis .right-devis .duree-devis p span{
    font-size: 12px;
    font-family: "RoobertEnel-Bold";
    background-color: #EDF3FE;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 12px;
    color: #135BF6;
}
.devis-container .devis-wrapper .details-devis .right-devis .estimation-devis .blocs h5{
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: "RoobertEnel-Regular";
    color: #000;
}
.devis-container .devis-wrapper .details-devis .right-devis .estimation-devis .estime .price{
    display: flex;
    flex-direction: column;
}
.devis-container .devis-wrapper .details-devis .right-devis .estimation-devis .estime .price p{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: end;
    font-size: 22px;
    line-height: 22px;
    font-family: "RoobertEnel-Bold";
    color: #E90657;
}
.devis-container .devis-wrapper .details-devis .right-devis .estimation-devis .estime .price p span{
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: "RoobertEnel-Bold";
    color: #E90657;
    margin-left: 10px;
    align-items: end;
}
.devis-container .devis-wrapper .details-devis .right-devis .estimation-devis .estime .price span{
    font-size: 12px;
    font-family: "RoobertEnel-Regular";
    color: #000;
    display: block;
    text-align: right;
}
.devis-container .devis-wrapper .details-devis .right-devis .estimation-devis .blocs p{
    font-size: 12px;
    font-family: "RoobertEnel-Regular";
    color: #000;
    display: block;
}
.devis-container .devis-wrapper .form-devis{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.devis-container .devis-wrapper .form-devis .select.dropdown-postal{
    margin-bottom: 0;
}
.devis-container .devis-wrapper .form-devis .dropdown-postal .selected .value,
.devis-container .devis-wrapper .form-devis .dropdown-postal .dropdown .value {
    color: #667790;
}
.tooltip-devis{
    display: inline;
    background: #8F9FAD;
    border-radius: 50%;
    padding: 0px 8px;
    margin-left: 8px;
    color: #fff;
}
.devis-container .devis-wrapper .form-devis .frm-input .select,
.devis-container .devis-wrapper .form-devis .frm-input .simple_input,
.devis-container .devis-wrapper .form-devis .frm-input .simple_input_phone{
    max-width: 90%;
    width: 100%;
}
.devis-container .devis-wrapper .form-devis .frm-input{
    padding-left: 0px;
}
.devis-container .devis-wrapper .form-devis .frm-input:nth-child(odd){
    padding-right: 0px;
    padding-left: 0;
}
.devis-container .devis-wrapper .form-devis .frm-input{
    flex: 50%;
}
.devis-container .devis-wrapper .form-devis .btn_devis{
    width: 95%;
    text-align: center;
    background: #E90657;
    margin: 0;
    padding: 0;
    z-index: 1;
}

@media screen and (max-width: 992px){
    .devis-container .devis-wrapper .details-devis{
        flex-direction: column;
    }
    .devis-container .devis-wrapper .details-devis .left-devis{
        margin-top: 10px;
        flex: 100%;
        text-align: center;
    }
    .devis-container .devis-wrapper .details-devis .right-devis{
        margin-top: 20px;
        width: 100%;
        flex: 100%;
    }
    .devis-container .devis-wrapper .details-devis .right-devis .duree-devis{
        width: 40%;
    }
    .devis-container .devis-wrapper .details-devis .right-devis .estimation-devis{
        width: 60%;
    }
}
@media screen and (max-width: 768px){
    .devis-container .devis-wrapper .form-devis .frm-input.select-offer,
    .devis-container .devis-wrapper .form-devis .frm-input.select-duree{
        flex: 100%;
    }
    .devis-container .devis-wrapper .form-devis .frm-input.select-offer .dropdown-postal{
        max-width: 95%;
    }
}
@media screen and (max-width: 576px){
    .devis-container{
        padding: 10px 20px;
    }
    .devis-container .devis-wrapper .details-devis .right-devis .duree-devis,
    .devis-container .devis-wrapper .details-devis .right-devis .estimation-devis{
        width: 100%;
    }
    .devis-container .devis-wrapper .details-devis .right-devis .estimation-devis{
        margin-top: 20px;
    }
    .devis-container .devis-wrapper .form-devis .frm-input{
        flex: 100%;
    }
    .devis-container .devis-wrapper .form-devis .frm-input.select-offer .dropdown-postal{
        max-width: 90%;
    }
}
.marge-top-aide{
    top: 280px !important;
}