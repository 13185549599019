.offer_container.disable-me{
    position: relative;
    overflow: hidden;
    cursor: not-allowed;
}
.offer_container.disable-me:before{
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: #ffffff;
    opacity: .5;
    z-index: 99;
}
.offer_container.offer_container.disable-me .no-offer-available {
    z-index: 99;
    border: 1px solid #E90657;
}
.offer_container.offer_container.disable-me .no-offer-available p{
    text-align: center;
    color: #E90657;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
}
.offers_container .offer_container.selected .inner_offer{
    background:#ffffff;
}
.offers_container .offer_container.selected .choose_offer{
    /*background:#E90657 url('../../assets/img/choose.png') no-repeat 90% center;*/
    background:#E90657 url('../../assets/img/thumb_up.svg') no-repeat 90% center;
    padding: 0 0 0 15px;
    text-align: left;
}
.offers_container .offer_container .tarif_offer{
    background:#ffffff;
    border-radius:5px;
    padding:10px;
    display: inline-block;
    margin: 10px 0 0;
}
.offers_container .offer_container .tarif_offer p{
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    width:100%;
    display: block;
    margin:0;
    padding:0;
    font-size:11px;
    color:#0F141A;
    display: inline-block;
    line-height: 0;
}
.offers_container .offer_container .tarif_offer p span{
    float: right;
    color:#667790;
}
.offers_container .offer_container.selected .tarif_offer{
    background:#EDF3FF;
}
.box_recommandons_3{
    width:100%;
    height:66px;
    background:#EFF2F7;
    border-radius:5px;
    overflow: hidden;
    margin:0;
    text-align: left;
}
.box_recommandons_3 .icon{
    width:66px;
    height:66px;
    float: left;
    background:#E90657 url('../../assets/img/sun.svg') no-repeat center center;
}
.box_recommandons_3 .txt_link{
    height:66px;
    float: left;
    padding-top: 24px;
}
.box_recommandons_3 .txt_link p{
    font-size:12px;
    color:#0F141A;
    padding-left:15px;
}
.box_recommandons_3 p span{
    color:#E90657;
}
.offers_container .offer_container .tarif_offer .info_price{
    /*width:66%;*/
    width: 100%;
    float: right;
    text-align: right;
}

.offers_container .offer_container .tarif_offer .offer_promo{
    color:#667790;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    font-size:13px;
    text-decoration: line-through;
    line-height: 46px;
    float: none;
}
.offers_container .offer_container .tarif_offer .offer_annual_price{
    float: left;
    text-align: right;
    color:#667790;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    width:100%;
    font-size:10px;
}
.offers_container .offer_container .tarif_offer .offer_prixe{
    color:#E90657;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    font-size:25px;
    /*font-size: 15px;*/
    margin:0 5px;
    height: 35px;
    line-height: 35px;
    float: none;
}
.offers_container .offer_container .tarif_offer .offer_unity_month{
    color:#E90657;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    font-size:14px;
    line-height: 40px;
    float: none;
}
.dure-contrat p{
    color: #667790 !important;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    font-size: 14px;
}
.dure-contrat h6{
    color: #0F141A !important;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    font-size: 14px;
}
.promotion-text{
    font-size: 13px !important;
    color: #667790 !important;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    margin-top: 26px;
}
.promotion-text .promotion-rate{
    color: #E90657;
}
/* MOBILE CSS */
@media screen and (max-width: 576px){
    .box_recommandons_3{
        overflow: visible;
    }
    .box_recommandons_3 .icon{
    }
    .box_recommandons_3 .txt_link{
        float: right;
        padding: 15px 0;
        width: calc(100% - 66px);
    }
}