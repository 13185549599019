.header{
    float:left;
    display: block;
    width:100%;
    height:93px;
    background:#ffffff;
    position:relative;
}
.header .logo_container{text-align: center; height: 93px;}
.logo{width:140px; margin:auto;display: inline-block;line-height: 65px;}
.logo img{width: 100%;}
.status_done{
    position: absolute;
    left:0;
    bottom:15px;
    height:2px;
    background:#E90657;
    z-index: 10;
}
.status{
    position: absolute;
    left:0;
    bottom:15px;
    height:2px;
    background:url('../../assets/img/encours.png') repeat-x;
    z-index: 9;
}
@media screen and (max-width:992px){
    .status, .status_done{
        bottom: -77px;
    }
}
/*STEPS TITLE*/
.head_step{
    height:78px;
    width:20%;
    float:left;
    display: block;
    text-align: center;
    border-bottom:1px solid #C2C8D2;
}
.head_step p{
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    font-size:10px;
    text-transform:uppercase;
    color:#667790;
}
.head_step.current_head_step{
    background:#EDF3FF;
}
.head_step.current_head_step.head_step_society{
    background:#EDF3FF;
}
.head_step.current_head_step p{
    color:#0555FA;
}
.head_step .step_bullet{
    height:18px;
    width:18px;
    background:#C2C8D2;
    margin:3px auto 0;
    display: block;
    border-radius:50%;
    border: 4px solid #ffffff;
}
.head_step .step_number{
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    height:27px;
    width:27px;
    background:#667790;
    color:#FFFFFF;
    text-align: center;
    margin:0 auto;
    /*display: block;*/
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    /*font-size:11px;*/
    line-height: 20px;
    border-radius: 50%;
    border: 4px solid #ffffff;
    position: relative;
    z-index: 11;
}
.head_step .step_current{
    background:#FF0F64;
}
.head_step .img_step_container{
    height:50px;
    padding:12px 0;
}
.mobileMenuContent, .mobileHelp, .toggleMobileWrapper, .mobileHelpBloc, .mobileHelpBlocParent, .lightShowBlue, .clockShow, .shopShow{
    display: none;
}
.desktopMenuContent{
    display: block;
}
/* MOBILE CSS */
@media screen and (max-width: 992px){
    .wrapper-popups{
        position: fixed;
        right: 15px;
        top: 70%;
        z-index: 1000;
        cursor: pointer;
    }
    .logo{
        line-height: 75px;
        height: 100%;
        justify-content: center;
        display: inline-flex;
    }
    .mobileMenuContent, .toggleMobileWrapper, .mobileHelpBloc, .mobileHelpBlocParent, .lightShowBlue, .clockShow, .shopShow{
        display: block;
    }
    .desktopMenuContent, .desktopHelpBloc, .desktopHelpBlocParent{
        display: none;
    }
    .current_head_step {
        width: 100%;
        height: 62px;
        background: #EDF3FF;
        float: left;
        display: block;
        text-align: center;
    }
    .current_head_step p{
        color: #0555FA;
        /*font-family: "UniversLTPro-65Bold";*/
        font-family: "RoobertEnel-Bold";
        font-size: 15px;
        text-transform: uppercase;
    }
    .steps_pins{
        height: 62px;
        width: 20%;
        float: left;
        display: block;
        text-align: center;
        /*border-bottom: 1px solid #C2C8D2;*/
    }
    .steps_pins .step_number{
        /*font-family: "UniversLTPro-65Bold";*/
        font-family: "RoobertEnel-Bold";
        height: 27px;
        width: 27px;
        background: #667790;
        color: #FFFFFF;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 9px;
        line-height: 20px;
        border-radius: 50%;
        border: 4px solid #ffffff;
        position: relative;
        z-index: 11;
    }
    .steps_pins .step_current {
        background: #FF0F64;
    }
    .steps_pins .step_bullet {
        height: 18px;
        width: 18px;
        background: #C2C8D2;
        margin: 3px auto 0;
        display: block;
        border-radius: 50%;
        border: 4px solid #ffffff;
    }
    .mobileHelp{
        display: block;
    }
    .mobileHelp .aide{
        top: 0;
        position: absolute;
        z-index: 999;
        left: 0;
    }
    .header .logo_container{
        text-align: initial;
    }
    .header .toggle{
        display: inline-block;
    }
    p.toggle_close {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 9999;
    }
    .toggleMobile{
        float: right;
    }
    .toggleMobileWrapper{
        background: #0555fa;
        padding: 5px 15px;
        border-radius: 10px;
        margin-top: 5px;
        position: relative;
        z-index: 100;
    }
    .toggleMobile span, .toggleMobile .aide_toggle{
        display: block;
    }
    .toggleMobile span{
        font-size: 11px;
        /*font-family: "UniversLTPro-55Roman";*/
        font-family: "RoobertEnel-Regular";
        color: #FFFFFF;
        margin-top: 10px;
    }
    .toggleMobile button{
        float: none;
        margin-top: 5px;
        margin-left: 20px;
        border:none;
        height:42px;
        width:42px;
        border-radius: 50%;
        position: relative;
        top: 0px;
        background:#ffffff url('../../assets/img/phonePink.png') no-repeat center center;
        background-size: 35px;
    }
    .toggleMobile .active_aide{
        background:#0555FA url('../../assets/img/phoneWhite.png') no-repeat center center;
        background-size: 35px;
    }
    .toggleMobile button:focus,.toggleMobile button:active,.toggleMobile button:visited{
        outline: none;
        border: none;
    }
    .mobileToggleLinks{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgb(102, 119, 144,.45);
        z-index: 1001;
    }
    .mobileToggleLinks .linksMobileWrapper {
        display: block;
        right: 15px;
        position: absolute;
        top: 100px;
    }
    .mobileToggleLinks .linksMobileWrapper a{
        display: block;
        margin-bottom: 10px;
        width: 145px;
        height: 44px;
        line-height: 44px;
        padding: 0 0 0 15px;
        /*font-family: "UniversLTPro-65Bold";*/
        font-family: "RoobertEnel-Bold";
        font-size: 11px;
        font-weight: 600;
        color: #e90657;
        text-transform: uppercase;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
    }
    .mobileToggleLinks .linksMobileWrapper a.contact_link{
        background:#ffffff url('../../assets/img/msg_aide.svg') no-repeat 90% center;
    }
    .mobileToggleLinks .linksMobileWrapper a.faq_link{
        background:#ffffff url('../../assets/img/faq_aide.svg') no-repeat 90% center;
    }
    .mobileToggleLinks .linksMobileWrapper a.rappel_link{
        background:#ffffff url('../../assets/img/phone_aide.svg') no-repeat 90% center;
    }
    .lightShowBlue{
        position: fixed;
        right: 15px;
        /*top: 80%;*/
        top: calc(100vh - 160px);
        z-index: 1000;
        cursor: pointer;
    }
    .clockShow{
        position: fixed;
        right: 15px;
        /*top: 70%;*/
        top: calc(100vh - 230px);
        z-index: 1000;
        cursor: pointer;
    }
    .shopShow{
        position: fixed;
        right: 15px;
        /*top: 60%;*/
        top: calc(100vh - 300px);
        z-index: 1000;
        cursor: pointer;
    }
    .btn_later_mobile{
        position: fixed;
        right: 80px;
        /*top: 70%;*/
        top: calc(100vh - 230px);
        z-index: 1000;
        background: #0555fa;
        padding: 10px 15px;
        color: #fff;
        border: none;
        border-radius: 5px;
        outline: none;
        /*font-family: "UniversLTPro-55Roman";*/
        font-family: "RoobertEnel-Regular";
        font-size: 15px;
        height: 52px;
    }
    .mobileHelpBlocParent {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        z-index: 1000;
    }
    .wrapperCloseMobile{
        text-align: center;
        margin-top: 20px;
    }
    .wrapperCloseMobile span{
        /*font-family: "UniversLTPro-55Roman";*/
        font-family: "RoobertEnel-Regular";
        font-size: 11px;
        color: #fff;
    }
    .closeMobileImg {
        background: #E90657;
        border-radius: 50%;
        width: 52px;
        height: 52px;
        margin: 0 auto;
    }
    .closeMobileImg img{
        margin: auto;
        display: block;
        height: 100%;
        object-fit: none;
    }
    .active_step_section{
        margin-top: 14px;
    }
    .active_step_section img, .active_step_section p{
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
    }
    .popup_offer_selected_mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,.7);
        z-index: 1000;
    }
    .popup_offer_selected_mobile .wrapper_offer_mobile .selected-offer-wrapper .selected-offer-aide{
        border: 1px solid #8291a5;
    }
}