@keyframes animate_ampoule {
    0% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3);
    }
}
.aide{
    /*height:705px;*/
    /*height: calc(100% - 10px);*/
    /*max-height: 705px;*/
    height: 100%;
    position:relative;
    overflow: hidden;
}
@media screen and (max-width: 1200px){
    .aide{
        height: calc(100% - 0px);
    }
}
.hide-infos-build{
    display: none;
}
.aide .circle_aide{
    position: absolute;
    right:0;
    top:0;
    z-index: 0;
}
.aide p{
    color:#ffffff;
    font-size:15px;
    position:relative;
    margin-top:50px;
    margin-right: 34px;
    display: block;
    float: right;
    z-index: 1;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    cursor: pointer;
}
.aide .circle_aide{
    position:absolute;
    right:-40%;
    top:-24%;
    z-index: 0;
}
.bg_aide1{
    position:absolute;
    left:10%;
    bottom:0;
    z-index: 0;
}
.aide p button{
    float: right;
    margin-left: 20px;
    border:none;
    height:59px;
    width:59px;
    border-radius: 50%;
    position: relative;
    top: -19px;
    background:#ffffff url('../../assets/img/phonePink.png') no-repeat center center;
    background-size: 40px;
}
.aide .active_aide{
    background:#0555FA url('../../assets/img/phoneWhite.png') no-repeat center center;
    background-size: 40px;
}
.aide p button:focus,.aide p button:active,.aide p button:visited{
    outline: none;
    border: none;
}
.aide .aide_links{
    position:relative;
    margin-top:0;
    margin-right: 34px;
    float: right;
    display: block;
    clear: right;
    z-index: 99;
}
.aide .aide_links a{
    display: block;
    margin-bottom: 10px;
    width:145px;
    height:44px;
    line-height:44px;
    padding: 0 0 0 15px;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    font-size:11px;
    font-weight: 600;
    color:#E90657;
    text-transform:uppercase;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}
.aide .aide_links a:hover{
    text-decoration: none;
}
.aide .aide_links a.contact_link{
    background:#ffffff url('../../assets/img/msg_aide.svg') no-repeat 90% center;
}
.aide .aide_links a.faq_link{
    background:#ffffff url('../../assets/img/faq_aide.svg') no-repeat 90% center;
}
.aide .aide_links a.rappel_link{
    background:#ffffff url('../../assets/img/phone_aide.svg') no-repeat 90% center;
}
.aide .toggleBuld.animate{
    animation: animate_ampoule 1.5s infinite;
}
.aide .toggleBuld{
    width:36px;
    height:37px;
    background:url('../../assets/img/light_bulb.svg') no-repeat center;
    position: absolute;
    left:50px;
    top:120px;
    cursor: pointer;
}
@media screen and (max-width : 992px){
    .aide .toggleBuld{
        display: none;
    }
}
.aide .toggleInfo{
    position: absolute;
    top:170px;
    background:#EFF2F7;
    left:30px;
    right:30px;
    padding:20px 15px;
    display: block;
    height: fit-content;
    border-radius: 5px;
    z-index: 9;
}

@media screen and (min-width: 992px) , screen and (max-height: 620px) {
    /* width */
    .aide .toggleInfo::-webkit-scrollbar {
        width: 8px;
    }
    
    /* Track */
    .aide .toggleInfo::-webkit-scrollbar-track {
        /*box-shadow: inset 0 0 5px grey;*/
        border-radius: 10px;
    }
    
    /* Handle */
    .aide .toggleInfo::-webkit-scrollbar-thumb {
        background: #E90657;
        border-radius: 10px;
    }
    .aide .toggleInfo{
        max-height: calc(100% - 300px);
        overflow-y: scroll;
    }
    .aide .toggleInfo.mobileInfos{
        max-height: 100%;
    }
}
.aide .toggleInfo p{
    font-size:15px;
    color:#0555FA;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    margin:0;
    display: block;
    clear: both;
    float: left;
    width: 100%;
}
.aide .toggleInfo .rappeler{
    cursor: pointer;
    color:#ffffff;
    background: #E90657;
    text-align: center;
    border-radius: 5px;
    padding: 2px 5px;
    margin-top:20px;
    display: block;
    float: left;
    font-size: 16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.aide .toggleInfo .link_redirect{
    color:#E90657;
    /*
    cursor: pointer;
    background: #E90657;
    text-align: center;
    border-radius: 5px;
    margin-right: 3px;
    padding: 2px 5px;
    */
    display: inline-block;
    font-size: 16px;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    text-decoration: none;
}
.toggle-info.active-marge-toggle .toggleInfo{
    top: 280px;
}
.toggle-info.active-marge-toggle .toggleBuld{
    top: 230px;
}
.toggle-info.active-marge-toggle .selected-offer-wrapper{
    margin-top: 300px;
}
.aide .btn_later,.aide .btn_later:visited,.aide .btn_later:active{
    outline: none;
    position: absolute;
    right:40px;
    /*bottom:55px;*/
    bottom: 105px;
    width:209px;
    height:56px;
    line-height:52px;
    border:1px solid #ffffff;
    border-radius: 5px;
    color:#ffffff;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    font-size:15px;
    text-align: left;
    padding: 0 0 0 20px;
    background:#0555FA url('../../assets/img/clock.svg') no-repeat 90% center;
    transition: all 250ms ease-in-out;
}
.aide .btn_later:hover{
    color:#0555FA;
    background:white;
    text-align: center;
    padding: 0;
    border:1px solid #0555FA;
    transition: all 250ms ease-in-out;
}
.app {
    position: relative;
}
.bg_pop_up2{
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background:url('../../assets/img/bg_white.png');
}
.popup-wrapper{
    /*
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .95);
    z-index: 9999;
    */
}
.popup_update_mail{
    /*
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .95);
    z-index: 9999;*/
}

.popup-wrapper .popup{
    /*max-width: 800px;*/
    /*margin: 100px auto;*/
    border: 1px solid #0555FA;
    /*background: rgba(255, 255, 255, 1);*/
    border-radius: 5px;
    padding: 30px 100px;
    position: relative;
}

.popup-wrapper .popup .popup_inner .btn-close,
.error_popup_rappel .btn-close{
    background: none;
    border: none;
    position: absolute;
    top: 30px;
    right: 30px;
}

.popup-wrapper .popup .popup_inner .phone_img, 
.error_popup_rappel .phone_img{
    margin: 0 auto;
    width: 95px;
    height: 86px;
    display: block;
    margin-bottom: 10px;
}

.popup-wrapper .popup .popup_inner h1,
.error_popup_rappel .popup_inner h1{
    color: #0555FA;
    font-size: 30px;
    /*font-family: "UniversLTPro-45Light";*/
    font-family: "RoobertEnel-Light";
    text-align: center;
    margin: 0;
    padding: 0;
}

.popup-wrapper .popup .popup_inner h2,
.error_popup_rappel .popup_inner h2{
    color: #0F141A;
    font-size: 16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 40px;
}
.popup-wrapper .popup .popup_inner .popup_continuer_p{
    color: #0F141A;
    font-size: 16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    margin: 0;
    padding: 0;
    margin-top: 40px;
}

.popup-wrapper .popup .popup_inner h2 span,
.error_popup_rappel .popup_inner h2 span{
    color: #0555FA;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
}

.popup-wrapper .popup .popup_inner label,
.error_popup_rappel .popup_inner label{
    color: #0F141A;
    font-size: 16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    display: block;
}

.popup-wrapper .popup .popup_inner label.radio_5,
.error_popup_rappel .popup_inner label.radio_5{
    color: #667790;
    font-size: 16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}

.popup-wrapper .popup .popup_inner label.label_names span,
.popup-wrapper .popup .popup_inner label.active span,
.error_popup_rappel .popup_inner label.label_names span,
.error_popup_rappel .popup_inner label.active span{
    color: #0555FA;
}
.popup-wrapper .popup .popup_inner .btn_rappel,
.error_popup_rappel .popup_inner .btn_rappel{
    width: 238px;
    height: 56px;
    margin: 0 auto;
    display: block;
    line-height: 50px;
    color: #ffffff;
    background: #E90657 url('../../assets/img/to_right.png') no-repeat 90% center;
    padding: 0 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: left;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-wrapper .popup .popup_inner .btn_simulation,
.error_popup_rappel .popup_inner .btn_simulation{
    width: 265px;
    height: 56px;
    margin: 0 auto;
    display: block;
    line-height: 50px;
    color: #ffffff;
    background: #E90657 url('../../assets/img/to_right.png') no-repeat 90% center;
    padding: 0 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: left;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}

.popup-wrapper .popup .popup_inner .rappel_label input,
.error_popup_rappel .popup_inner .rappel_label input{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.popup-wrapper .popup .popup_inner .rappel_label input:checked,
.error_popup_rappel .popup_inner .rappel_label input:checked{
    background: url('../../assets/img/check_2_active.png');
    appearance: none;
    outline: none;
}
.popup-wrapper .popup .popup_inner .rappel_label,
.error_popup_rappel .popup_inner .rappel_label{
    font-size: 14px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    color: #667790;
    display: flex;
    align-items: center;
}

.popup-wrapper .popup .popup_inner .rappel_label.active,
.error_popup_rappel .popup_inner .rappel_label.active{
    color: #0555FA;
}

.list_days{
    width: 100%;
    max-width: 280px;
    height: 50px;
    border: 1px solid #C2C8D2;
    border-radius: 5px;
}

.list_days .list_days_options {
    width: calc(100% - 51px);
    height: 50px;
    overflow: hidden;
    float: left;
}

.list_days .list_days_options.show_list {
    overflow: visible;
    height: -moz-fit-content;
    height: fit-content;
}

.list_days .list_days_options p {
    height: 50px;
    line-height: 50px;
    display: block;
    color: #C2C8D2;
    font-size: 16px;
    padding: 0 0 0 15px;
    cursor: pointer;
}
.list_days .trigger_list_box {
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    overflow: hidden;
    float: left;
    border-left: 1px solid #C2C8D2;
    cursor: pointer;
}
.list_days .trigger_list_box i {
    color: #0555FA;
    font-size: 18px;
    font-weight: lighter;
}
.choosen_day{
    display: inline-block;
    width: 100%;
    padding: 0;
}
.choosen_day p {
    cursor: pointer;
    color: #0555FA;
    font-size: 16px;
    margin-bottom: 8px;
    width: 100%;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.popup_continuer .wrapper-main{
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
}
.popup_continuer .radioPad__wrapper{
    background: #fff ;
    border: 1px solid #0555FA ;
    max-width: 118px;
    width: 100%;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.popup_continuer .radioPad__wrapper label{
    color: #667790 !important;
}
.popup_continuer .radioPad__wrapper label input{
    display: block;
}
.popup_continuer .radioPad__wrapper.radioPad__wrapper--selected{
    background: #0555FA ;
}
.popup_continuer .radioPad__wrapper.radioPad__wrapper--selected label{
    color: #fff !important;
}
.popup_continuer .popup .popup_inner .lead{
    text-align: center;
    margin-top: 20px;
}
.grecaptcha-badge{
    z-index: 100;
}
.only_mobile{
    display: none;
}

.selected-offer-wrapper {
    margin-top: 180px;
}
.selected-offer-wrapper .selected-offer-aide{
    background: #EFF2F7;
    max-width: 255px;
    padding: 10px 14px;
    width: 100%;
    border-radius: 10px;
    position: relative;
    margin: 0 auto;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-20%, -50%);
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-title h1{
    font-size: 13px;
    color: #667790;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    padding: 0;
    margin: 0;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-title h3{
    font-size: 16px;
    color: #0555FA;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    padding: 0;
    margin: 0;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-details {
    background: #fff;
    padding: 7px 9px;
    border-radius: 5px;
    margin-top: 12px;
    margin-bottom: 21px;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-details h4{
    font-size: 10px;
    margin: 0;
    padding: 0;
    display: inline-block;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    color: #0F141A;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-details p{
    font-size: 13px;
    color: #667790;
    display: inline-block;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    margin: 0;
    padding: 0;
    text-decoration: line-through;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-details p span.price-new{
    font-size: 25px;
    color: #E90657;
    display: inline-block;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    margin: 0;
    padding: 0;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-details p span.text{
    font-size: 13px;
    color: #E90657;
    display: inline-block;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    margin: 0;
    padding: 0;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-details p.small-text{
    font-size: 11px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    display: block;
    width: 100%;
    text-align: right;
    color: #667790;
    margin: 0;
    padding: 0;
    text-decoration: none;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-details h4 span{
    color: #0555FA;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-docu a{
    position: relative;
    color: #667790;
    font-size: 13px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    margin: 0;
    padding: 0;
    margin-top: 21px;
    text-decoration: none;
}
.selected-offer-wrapper .selected-offer-aide .offer-selected-content-docu a:before{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #667790;
}
.desktopHelpBlocParent {
    position: fixed !important;
    right: 0;
    top: 0;
}

/* MOBILE CSS */
@media screen and (max-width: 576px){
    .only_desktop{
        display: none;
    }
    .only_mobile{
        display: block;
        margin: 0 auto;
    }
    .popup{
        width: 100%;
        max-width: calc(100% - 0px) !important;
        padding: 75px 15px !important;
        margin: 0 auto !important;
        left: 0%;
        right: 0%;
        top: 4%;
    }
    .popup .popup_inner h1{
        font-size: 22px !important;
        padding-left: 0 !important;
    }
    .popup .popup_inner h1:before{
        display: none;
    }
    .popup .popup_inner .confimation-pop{
        text-align: center;
    }
    .popup .popup_inner .marg_top_input{
        margin-top: 3rem;
    }
    .popup_continuer .popup .popup_inner .radioPad__wrapper{
        margin-bottom: 20px;
    }
    .popup_continuer .popup .popup_inner .lead img{
        width: 100%;
    }
    .popup-wrapper .popup .popup_inner .btn-close{
        top: -25px;
        right: 30px;
        /*background: #E90657;*/
        border-radius: 50%;
        width: 52px;
        height: 52px;
    }
}
.btn_rappel_error{
    width: 165px;
    height: 56px;
    line-height: 50px;
    color: #fff;
    background:#E90657 url('../../assets/img/to_right.png') no-repeat 90% center;
    padding: 0 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: left;
    font-family: "RoobertEnel-Regular";
    position: relative;
    z-index: 10;
    margin: 20px auto 0;
    display: block;
}
.btn_rappel_error_conso{
    min-width: 165px;
    height: 56px;
    line-height: 50px;
    color: #fff;
    background:#E90657 url('../../assets/img/to_right.png') no-repeat 90% center;
    padding: 0 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: left;
    font-family: "RoobertEnel-Regular";
    position: relative;
    z-index: 10;
    margin: 20px auto 0;
    display: inline-block;
    vertical-align: middle;
}
.wrapper-buttons-error{
    text-align: center;
}
.conso_width{
    min-width: 280px;
    margin-left: 30px;
}
.phone_img_true{
    display: block;
    margin: 0 auto;
}
.retour-popup{
    position: relative;
    background:#E90657;
    padding: 5px 20px;
    border-radius: 5px;
    color: #fff;
    font-family: "RoobertEnel-Regular";
    font-size: 15px;
    cursor: pointer;
}
.retour-popup:before {
    position: absolute;
    content: '';
    background:url('../../assets/img/to_left.png');
    background-repeat: no-repeat;
    top: 0;
    left: 5px;
    transform: translate(0%, 50%);
    width: 8px;
    height: 13px;
}
@media screen and (max-width: 992px){
    .wrapper-back-popup{
        margin-bottom: 20px;
    }
}
.btn-rappel-popin{
    margin: 30px auto 0 !important;
    display: block;
}