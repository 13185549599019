.offer_container{
    position: relative;
    max-width:280px;
    width: 100%;
    display: inline-block;
    height: auto;
    margin:0 25px 0 0;
    vertical-align:top;
}
.offer_container .offer_icone{
    /*position:relative;*/
    /*left:65%;*/
    /*z-index: 9;*/
    /*top:37px;*/
    /*width: 75px;*/
    /*height: 75px;*/
}
.offer_container .icon_wrapper{
    position:relative;
    left:65%;
    z-index: 9;
    top:37px;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.offers-wrapper .inner_offer{
    min-height: 381px;
    position: relative;
}
.offers-wrapper .offres_inner_offer{
    display: flex !important;
    flex-direction: column;
    /*justify-content: space-between;*/
    justify-content: flex-start;
}
.offers-wrapper .offres_inner_offer .begin-bloc{
    min-height: 164px;
}
.offers-wrapper .offres_inner_offer .end-bloc{
}
.tarif-wrapper .tarif_inner_offer {
    min-height: 555px;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
}
.offer_container .inner_offer{
    border-radius: 5px;
    display: block;
    height: fit-content;
    padding:25px 20px;
    background:#EDF3FF;
    border:1px solid #C2C8D2;
}
.offer_container.selected .inner_offer{
    border:1px solid #0555FA;
}
.offer_container .inner_offer h2{
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    font-size:19px;
    color:#0555FA;
    margin: 0 0 15px;
    padding:0;
}
.offer_container .inner_offer h2 em{
    font-size:14px;
    font-family: "georgiaItalic";
}
.offer_container .inner_offer p{
    color:#0F141A;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    font-size:14px;
}
.offer_container .inner_offer .toggle_avantage{
    color:#0F141A;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    font-size:14px;
    display: block;
    margin:18px 0 10px;
    /*cursor: pointer;*/
    background:none;
    position: relative;
}
.offer_container .inner_offer .toggle_avantage button{
    background:transparent;
    border:none;
    color:#0F141A;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    font-size:14px;
    display: block;
    margin:18px 0 10px;
    cursor: auto;
    padding: 0 25px 0 0;
    position: relative;
}
.offer_container .inner_offer .toggle_avantage button.btn-show-more:before{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translate(0%, 80%);
    width: 14px;
    height: 8px;
    background:transparent url('../../assets/img/arrow_down.png') right center no-repeat;
}
.offer_container .inner_offer .toggle_avantage .btn-show-more{
    text-align: center !important;
    margin: 5px auto !important;
    font-size: 12px !important;
    text-decoration: underline !important;
    color: #0555fa !important;
    cursor: pointer !important;
}
.offer_container .inner_offer .toggle_avantage .btn-show-more.rotate-me:before{
    transform: translate(0%, 80%) rotate(-180deg);
}
.offer_container .inner_offer .toggle_avantage button:visited,
.offer_container .inner_offer .toggle_avantage button:active,
.offer_container .inner_offer .toggle_avantage button:focus{
    outline: none;
    border: none;
    box-shadow: none;
}
.offer_container .inner_offer .toggle_avantage button::after{
    display: none;
}
.offer_container .inner_offer .toggle_avantage .list_avantage{
    float: none;
    position: relative!important;
    transform: translate3d(0, 0, 0px)!important;
    padding:0!important;
    background:none!important;
    border:none!important;
    margin: 0 0 15px 0;
}
.offer_container .inner_offer .toggle_avantage .list_avantage.offer-show-one-line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.offer_container .inner_offer .toggle_avantage .list_avantage ul{
    margin:0;
    padding:0;
    margin-left: 8px;
    /*list-style: none;*/
}
.offer_container .inner_offer .toggle_avantage .list_avantage ul li{
    margin-left:5px;
    font-size:14px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    color: #667790;
    list-style-image: url('../../assets/img/bullet.svg');
}
.offer_container .inner_offer .toggle_avantage .list_avantage.tarif-show-one-line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.offer_container .inner_offer .toggle_avantage i{
    color:#0555FA;
    font-size:15px;
    font-weight: lighter;
    margin-left: 12px;
    height: 19px;
    width:19px;
    text-align: center;
    line-height: 15px;
}
.offer_container .choose_offer{
    cursor: pointer;
    width:230px;
    border-radius: 5px;
    background: #0555FA url('../../assets/img/thumb_right.svg') no-repeat 15px center;
    position: relative;
    top:-28px;
    left:25px;
    height:56px;
    line-height: 56px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    color:#ffffff;
    text-align: right;
    padding: 0 15px 0 0;
    font-size:15px;
}
.offer_container .download_brochure{
    /*font-family: "UniversLTPro-45Light";*/
    font-family: "RoobertEnel-Light";
    font-size:15px;
    color:#0F141A;
    height:25px;
    line-height: 5px;
    padding:15px 15px 15px 48px;
    background:url('../../assets/img/brochure.svg') no-repeat 15px center;
    display: block;
    margin: 15px 0;
}
.offer_container .offer_selected{
    background:#ffffff;
    color:#0555FA;
    border:#0555FA;
    width:131px;
    height:27px;
    line-height: 27px;
    text-align: center;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    font-size:11px;
    text-transform: uppercase;
    border: 1px solid #0555FA;
    border-radius: 5px;
    display: block;
    position: absolute;
    left:25px;
    top:62px;
    z-index: 9;
}
.box_recommandons_2{
    width:100%;
    height:66px;
    background:#EFF2F7;
    border-radius:5px;
    overflow: hidden;
    margin:0;
    text-align: left;
}
.box_recommandons_2 .icon{
    width:66px;
    height:66px;
    float: left;
    background:#E90657 url('../../assets/img/calc.svg') no-repeat center center;
}
.box_recommandons_2 .txt_link{
    height:66px;
    float: left;
    padding-top: 15px;
}
.box_recommandons_2 .txt_link p{
    font-size:12px;
    color:#0F141A;
    padding-left:15px;
}
.box_recommandons_2 p span{
    color:#E90657;
}

/* MOBILE CSS */
@media screen and (max-width: 576px){
    .box_recommandons_2{
        overflow: visible;
        height: 90px;
    }
    .box_recommandons_2 .icon{
        height: 90px;
    }
    .box_recommandons_2 .txt_link{
        float: right;
        padding: 9px 0;
        width: calc(100% - 66px);
        height: 90px;
    }
    .box_recommandons_2 .txt_link p {
        line-height: 16px;
        margin-top: 0;
        padding-left: 10px;
    }
    .box_recommandons_2 .txt_link a,
    .box_recommandons_2 .txt_link a:visited {
        padding-left: 10px;
    }
}

/* SMALL MOBILE CSS */
@media screen and (max-width: 320px){
    .offer_container .choose_offer{
        left: 15px;
    }
    .box_recommandons_2{
        height: 110px;
    }
    .box_recommandons_2 .icon{
        height: 110px;
    }
    .box_recommandons_2 .txt_link{
        height: 110px;
    }
    .simple_input.promo_input {
        width: 100% !important;
        display: block;
        margin-top: 10px;
    }
    .offers_container .btn_appliquer{
        margin: 0 auto;
        display: block;
        margin-top: 15px;
    }
}