/* Fonts*/
@font-face {
    font-family: "georgiaItalic";
    src: url("../fonts/georgiaitalic.woff2") format("woff2"),
      url("../fonts/georgiaitalic.woff") format("woff"),
      url("../fonts/georgiaitalic.ttf") format("truetype"),
      url("../fonts/georgiaitalic.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "UniversLTPro-65Bold";
    src: url("../fonts/UniversLTPro-65Bold.woff2") format("woff2"),
      url("../fonts/UniversLTPro-65Bold.woff") format("woff"),
      url("../fonts/UniversLTPro-65Bold.ttf") format("truetype"),
      url("../fonts/UniversLTPro-65Bold.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: "UniversLTPro-55Roman";
  src: url("../fonts/UniversLTPro-55Roman.woff2") format("woff2"),
    url("../fonts/UniversLTPro-55Roman.woff") format("woff"),
    url("../fonts/UniversLTPro-55Roman.ttf") format("truetype"),
    url("../fonts/UniversLTPro-55Roman.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UniversLTPro-45Light";
  src: url("../fonts/UniversLTPro-45Light.woff2") format("woff2"),
    url("../fonts/UniversLTPro-45Light.woff") format("woff"),
    url("../fonts/UniversLTPro-45Light.ttf") format("truetype"),
    url("../fonts/UniversLTPro-45Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UniversLTPro-75Black";
  src: url("../fonts/UniversLTPro-75Black.woff2") format("woff2"),
    url("../fonts/UniversLTPro-75Black.woff") format("woff"),
    url("../fonts/UniversLTPro-75Black.ttf") format("truetype"),
    url("../fonts/UniversLTPro-75Black.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RoobertEnel-Regular";
  src: url("../fonts/enel_roobert/RoobertENEL-Regular.woff2") format("woff2"),
    url("../fonts/enel_roobert/RoobertENEL-Regular.woff") format("woff"),
    url("../fonts/enel_roobert/RoobertENEL-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RoobertEnel-Light";
  src: url("../fonts/enel_roobert/RoobertENEL-Light.woff2") format("woff2"),
    url("../fonts/enel_roobert/RoobertENEL-Light.woff") format("woff"),
    url("../fonts/enel_roobert/RoobertENEL-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RoobertEnel-Bold";
  src: url("../fonts/enel_roobert/RoobertENEL-Bold.woff2") format("woff2"),
    url("../fonts/enel_roobert/RoobertENEL-Bold.woff") format("woff"),
    url("../fonts/enel_roobert/RoobertENEL-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{
  position: inherit;
}
.react-tel-input .country-list .country,
.react-datepicker{
  font-family: "RoobertEnel-Regular";
}
body,html{
    margin:0;
    padding:0;
    background:#ffffff;
    /*font-family: "Univers";*/
    font-family: "RoobertEnel-Regular";
}
.text_center{text-align: center;}
a,button,a:visited,button:visited{ outline: none; }
p{margin:0;padding: 0;}
.row{ margin:0; }
.inside_step{ width:100%; float:left; display: block;padding: 40px 5%; }
h1{
    /*font-family: "UniversLTPro-45Light";*/
    font-family: "RoobertEnel-Light";
    font-size:48px;
    color:#000000;
    display: block;
    padding:0 0 0 26px;
    position:relative;
}
h1 span{
    position:absolute;
    left:0;
    top:10px;
    width:9px;
    height:34px;
    background:#0555FA;
}
.encarts{
    background:#EDF3FF;
    min-height:300px;
    padding-top:40px;
    padding-bottom: 40px;
    padding-left: 5%;
}
.encarts img{
    height:54px;
    display: block;
    float:left;
}
.encarts h2{
    /*font-family: "Univers";*/
    font-size:20px;
    color:#0F141A;
    display: block;
    float:left;
    width: 100%;
    margin:10px 0 15px;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
}
.encarts p{
    float:left;
    /*font-family: "Univers";*/
    font-size:14px;
    color:#667790;
    width:100%;
    display: block;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.full_width{width:100%;}
.block{display: block;}
.left{float:left;}
.right{float: right;}
.noPadding{padding:0;}
.classic_txt{
  /*font-family: "Univers";*/
  font-size:16px;
  color:#0F141A;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
}
.classic_txt span{
  color:#0555FA;
  /*font-family: "UniversLTPro-65Bold";*/
  font-family: "RoobertEnel-Bold";
}
.loadingKey{
  text-align: center;
  position:fixed;
  left:0;
  right:0;
  top:0;
  height: 100%;
  background:url('../img/bg_white.png');
  z-index:99;
}
.loadingKey .spinner-border{
  position: absolute;
  top:40%;
}
.txt_center{text-align: center;}

.coordone_wrapper .list_CP{
  min-width: 85px !important;
  padding: 10px 30px 10px 10px !important;
  width: auto !important;
}
.btn_reset{
  color: #ffffff;
  padding: 16px 25px;
  border: none;
  outline: none;
  border-radius: 5px;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  background: #0F141A;
  cursor: pointer;
  position: relative;
  z-index: 10;
}
.btn_reset i{
  margin-right: 5px;
}
.swal-title{
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  color: #0F141A;
  text-align: center;
}
.countBoxItem{
  display: flex;
  align-self: center;
  margin-left: 10px;
}
.countBoxItem .label{
  display: none;
}
.countBoxItem .count{
  color: #0555FA !important;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular" !important;
  font-size: 12px !important;
}
.swal-button{
  line-height: 22px;
  border: none !important;
  outline:none !important;
}
.swal-button:focus,
.swal-button:active{
  border: none !important;
  outline:none !important;
  box-shadow: none !important;
}
.swal-text{
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  color: #0F141A;
  text-align: center;
}
.swal-button--confirm{
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  color: #fff;
  background-color: #0555FA !important;
  transition: .3s ease-in-out;
}
.swal-button--cancel{
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
}
.swal-button--confirm:hover{
  background-color: rgba(5, 85, 250, .8) !important;
}
.swal-icon--error__line{
  background-color: #E90657;
}
.swal-icon--error{
  border-color: #E90657;
}
.mobileMenu {
  display: none;
}
.add_copro_syndic{
  display: inline-block;
  vertical-align: top;
  clear: none;
  float: none;
}
.list_commune .list_box_options{
  width: calc(100% - 50px);
}
.syndic_infos{
  clear: both;
}
.list_commune{
  max-width: 200px;
  width: 100%;
  display: inline-block;
  margin-right: 10px;
  height: auto;
  vertical-align: top;
}
#syndic_frm .block .form_group{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 1024px){
  #syndic_frm .block .form_group{
    align-items: flex-start;
    flex-direction: column;
  }
}
#syndic_frm .block .form_group .space-right{
  margin-right: 10px;
}
#syndic_frm .block .form_group .space-right:last-child{
  margin-right: 0;
}
#syndic_frm .block .form_group .visible-mobile{
  display: none;
}
#syndic_frm .block .form_group .add_copro_syndic span{
  color: #0555FA;
  font-weight: lighter;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: 1px solid #667790;
  text-align: center;
  line-height: 24px;
  margin-left: 8px;
}
@media screen and (max-width: 576px){
  #syndic_frm .block .form_group{
    justify-content: center;
  }
  #syndic_frm .block .form_group .visible-mobile{
    display: inline-block;
    margin-right: 5px;
  }
}
.wrapper-input {
  display: inline-block;
  margin-right: 0px;
  vertical-align: top;
  /*width: 33%;*/
}
.list_cp{
  display: inline-block;
}
.names-copro{
  padding: 6px 10px;
  margin: 10px 10px 0 0;
  margin-top: 10px;
  border: 1px solid #0555FA;
  display: inline-block;
  border-radius: 5px;
  color: #0555FA !important;
}
.names-copro i{
  margin-left: 10px;
  cursor: pointer;
}
.payeur_divergent{
  height: 45px;
}
.payeur_divergent .icon, .payeur_divergent .txt_link{
  height: 45px;
}
.payeur_divergent .txt_link span{
  cursor: pointer;
  /*font-family: "UniversLTPro-65Bold";*/
  font-family: "RoobertEnel-Bold";
}
.copro_text{
  max-width: 200px;
  vertical-align: top;
  display: inline-block;
  /*margin-right: 10px;*/
  margin-right: 0px;
  margin-bottom: 15px;
}
.small-error-size{
  font-size: 12px !important;
}
.swal-content .list_errors ul{
  margin: 0;
  padding: 0;
}
.swal-content .list_errors ul li{
  list-style: none;
  font-family: "UniversLTPro-55Roman";
  font-size: 15px;
  color: #E90657;
  margin: 0;
  padding: 0;
}
.popup-wrapper .popup .popup_inner .btn_rappel:disabled,
.popup-wrapper .popup .popup_inner .btn_simulation:disabled{
  opacity: .7;
}
.red_star{
  color: #E90657 !important;
  /*font-family: "UniversLTPro-65Bold" !important;*/
  font-family: "RoobertEnel-Bold" !important;
}
.blank_div{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  z-index: 9;
}
.fa-arrow-down-own:before{
  content: '';
  background-image: url('../img/arrow_price.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 16px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.swal-modal.error-message .swal-text{
  list-style: none;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  font-size: 15px;
  color: #E90657;
  margin: 0;
  padding: 0;
}
.small-text-help.text-success{
  color: #28a745 !important;
}
.small-text-help.text-danger{
  color: #dc3545 !important;
}
.etablissement-wrapper{
  max-width: 591px;
  width: 100%;
  background: #EFF2F7;
  border-radius: 5px;
  overflow: hidden;
  margin: 0;
  display: flex;
}
.etablissement-wrapper .icon-help{
  flex: 0 0 66px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #E90657;
}
.etablissement-wrapper .etablissement-text{
  padding: 10px 15px;
}
.etablissement-wrapper .etablissement-text p{
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  font-size: 12px;
  color: #667790;
}
.etablissement-wrapper .etablissement-text p.infos{
  /*font-family: "UniversLTPro-65Bold";*/
  font-family: "RoobertEnel-Bold";
  font-size: 12px;
  color: #667790;
}
.etab-list-wrapper{
  display: block;
  width: 100%;
}
/**/
.select {
  position: relative;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  vertical-align: middle;
  display: inline-flex;
  width: 200px;
}
.select.select-etablissement{
  width: 360px;
}
.dropdown-postal .hide-drop{
  display: none;
}
.select .arrow, .select .toggle {
  cursor: pointer;
}
.info-text{
  font-size: 15px;
  color: #0F141A;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  margin: 0;
  display: block;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.react-datepicker-popper{
  z-index: 11;
}
/*
 * Hide the actual radio buttons.
 */
.select .radio {
  position: absolute;
  opacity: 0 !important;
  z-index: -1;
}
/*
 * Styles for selected value.
 */
.select .selected {
  width: calc(100% - 50px);
  height: 50px;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  font-size: 16px;
  line-height: 1.5;
  padding-left: 10px;
  padding-right: 36px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
}
.select .selected .value {
  height: 100%;
  display: flex;
  align-items: center;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  font-size: 16px;
  color: #C2C8D2;
  white-space: nowrap;
}
/*
 * Hide the value sibling
 * if checkbox is not selected.
 */
.select .selected .radio:not(:checked)+.value {
  display: none;
}
/*
 * Styles for dropdown.
 */
.offer_container.selected .select .dropdown{
  background: #fff !important;
}
.offer_container .select .dropdown{
  background: #EDF3FF !important;
}
.select .dropdown {
  position: absolute;
  top: 95%;
  left: -1px;
  width: 101%;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  border: 1px solid #C2C8D2;
  background: #fff;
  border-top: none;
  border-radius: 0 0 2px 2px;
  box-sizing: border-box;
  overflow: auto;
  z-index: 11;
  transition: opacity 0s, border-color 0s, max-height 0s;
  transition-delay: 0.125s, 0.125s, 0.125s;
}
.select .dropdown .value {
  line-height: 1.5;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  display: block;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  font-size: 16px;
  color: #C2C8D2;
  margin: 10px 0;
}
.select .dropdown .value:hover {
  color: #0555FA;
}
/*
 * Styles for the dropdown arrow.
 */
.select .arrow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
/*
 * Styles for the input field
 * which act as the toggle.
 */
.select .toggle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: none;
  outline: none;
  border: none;
}
.dropdown-type-file{
  width: 100%;
  max-width: 280px;
}
/*
 * If input does not have focus,
 * hide dropdown + some overrides.
 */
 /*
.select .toggle:not(:focus) {
  z-index: 2;
}
.select .toggle:not(:focus)~.arrow {
  z-index: 1;
}
.select .toggle:not(:focus)~.arrow:after {
  border-top-color: #212121;
  border-bottom-color: transparent;
  margin-top: -3px;
}
.select .toggle:not(:focus)~.selected {
  border-radius: 2px;
}
.select .toggle:not(:focus)~.dropdown {
  max-height: 0;
  border-color: transparent;
  opacity: 0;
}
*/
/*
 * If input has focus,
 * show dropdown + some overrides.
 */
 /*
.select .toggle:focus {
  z-index: 1;
}
.select .toggle:focus~.arrow {
  z-index: 2;
}
.select .toggle:focus~.arrow:after {
  border-top-color: transparent;
  border-bottom-color: #212121;
  margin-top: -9px;
}
.select .toggle:focus~.selected {
  border-radius: 2px 2px 0 0;
}
.select .toggle:focus~.dropdown {
  max-height: 180px;
  border-color: #212121;
  opacity: 1;
}
*/
/**/
/* Tooltip Style */
.tippy-popper .tippy-tooltip{
  color: #0555FA;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  font-size: 14px;
  text-align: left;
}
.tippy-tooltip [x-circle]{
  background-color: #EFF2F7 !important;
}
.offer_container.selected .simulation-wrapper{
  background: #EDF3FF;
}
.offer_container .simulation-wrapper{
  background: #FFFFFF;
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;
}
.offer_container .simulation-wrapper .estimation-wrapper span.estime_text{
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  font-size: 10px;
  color: #0F141A;
  position: absolute;
  left: 10px;
  top: 5px;
}
.offer_container .simulation-wrapper .estimation-wrapper .price_estimation p.pricing{
  /*font-family: "UniversLTPro-65Bold";*/
  font-family: "RoobertEnel-Bold";
  color: #E90657;
  font-size: 25px;
  text-align: right;
}
.offer_container .simulation-wrapper .estimation-wrapper .price_estimation p.pricing del{
  font-size: 13px;
  color: #667790;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  margin-right: 10px;
}
.offer_container .simulation-wrapper .estimation-wrapper .price_estimation p.pricing span{
  font-size: 13px;
  color: #E90657;
}
.offer_container .simulation-wrapper .estimation-wrapper .price_estimation p.pricing_annual{
  color: #667790;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  text-align: right;
  font-size: 10px;
  margin: 0;
  padding: 0;
  line-height: 0;
}
.offer_container .simulation-wrapper .details_wrapper{
  margin-top: 20px;
}
.offer_container .simulation-wrapper .details_wrapper p{
  font-size: 10px;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  color: #0F141A;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.offer_container .simulation-wrapper .details_wrapper p span{
  color: #667790;
}
.CookieDeclaration{
  display: none;
}
.wrapper-email-update, .wrapper-code-resend{
  display: flex;
}
.btn_edit_email:disabled,
.btn_resend_mail:disabled,
.btn_appliquer:disabled{
  opacity: .3;
  cursor: not-allowed;
}
.countdown-email-update{
  margin: 20px 0 0 20px;
}
.countdown-email-update .countBoxItem{
  display: inline-block;
  margin-right: 10px;
}
.countdown-email-update .countBox{
  display: flex;
  flex-direction: row-reverse;
}
.countdown-email-update .countBox .countBoxItem{
  font-size: 12px;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  color: #0F141A;
}
.info-text-wrapper .text-info ul{
  padding: 0;
  margin: 0;
  margin-left: 18px;
}
.info-text-wrapper .text-info ul li::marker{
  color: #0555FA;
}
.info-text-wrapper .text-info ul li{
  font-family: "RoobertEnel-Regular";
  font-size: 12px;
  color: #667790;
  font-style: italic;
}
#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentControls,
#CybotCookiebotDialogBodyContentTitle{
  font-family: "RoobertEnel-Regular";
}
.upload-file{
  border: 1px solid #c2c8d2;
  border-radius: 5px;
  width: 100%;
  min-height: 56px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
}
.date_input_client{
    border-radius: 5px;
    width:175px;
    height:50px;
    line-height: 45px;
    padding:0 25px 0 0;
    border:1px solid #667790;
    color:#667790;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    text-align: right;
    font-size:17px;
    background:#ffffff url('../../assets/img/calendrier.svg') 14px center no-repeat;
    background-size: 30px 50px;
}
.wrapper-files-upload{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  position: relative;
}
.wrapper-file-upload{
  margin-bottom: 0;
}
.input_file_upload{
  width: 400px !important;
  display: inline-block;
  margin: 0 20px;
}
.input_upload_selected_file{
  width: 300px !important;
  margin: 0 !important;
}
.wrapper-uploaded-files{
  display: flex;
  align-items: center;
}
.close-btn{
  margin-left: 20px;
  color: #e90657;
}
.input_file_upload .upload-file{
  min-height: 50px !important;
}
.cursor-file{
  cursor: pointer;
}
.add-file-btn{
  text-decoration: underline;
  color: #0555fa !important;
}
.date_input_client{
  border-radius: 5px;
  width:175px;
  height:50px;
  line-height: 45px;
  padding:0 25px 0 0;
  border:1px solid #667790;
  color:#667790;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  text-align: right;
  font-size:17px;
  background:#ffffff url('../../assets/img/calendrier.svg') 14px center no-repeat;
  background-size: 30px 50px;
}
.upload-file:before{
  background: url('../img/join-file.svg');
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-size: cover;
}
.upload-file .input-upload-file,
.upload-file input[type='file']{
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.upload-text{
  font-size: 12px;
  color: #667790;
  font-family: "RoobertEnel-Regular";
  padding: 0 !important;
  margin: 0 !important;
}
.name-uploaded-text{
  padding-right: 55px !important;
}
.inner_offer h2{
  padding-right: 50px !important;
}
.date_calendar_contact{
  border-radius: 5px;
  width: 280px;
  height:50px;
  line-height: 45px;
  padding: 0 0 0 60px;
  border:1px solid #667790;
  color:#667790;
  /*font-family: "UniversLTPro-55Roman";*/
  font-family: "RoobertEnel-Regular";
  text-align: left;
  font-size:17px;
  background:#ffffff url('../../assets/img/calendrier.svg') 14px center no-repeat;
  background-size: 30px 50px;
}
@media screen and (max-width: 992px){
  .address-text{
    /*display: inline-flex;*/
    flex-wrap: wrap;
    display: flex;
  }
  .grecaptcha-badge{
    display: none !important;
  }
  .btn_reset{
    margin-top: 10px;
  }
  .select .dropdown {
    width: 100.5%;
  }
  .copro_text{
    max-width: 250px;
  }
  .hide_me{
    display: none;
  }
  .inside_step{
    padding: 110px 5% 50px;
  }
  .wrapper-input{
    width: 50%;
    margin-bottom: 20px;
  }
}
/* MOBILE CSS */
@media screen and (max-width: 576px){
  .offer_container{
    display: block;
    margin: 0 auto;
  }
  .select.select-etablissement{
    width: 100%;
  }
  .etablissement-wrapper{
    margin-top: 50px;
  }
  .payeur_divergent{
    margin-top: 30px;
  }
  .mobileMenu {
    display: block;
  }
  .inside_step{
    padding: 40% 0;
  }
  .blue-border span{
    display: none;
  }
  .inside_step .blue-border:before {
    width: 9px;
    height: 33px;
    background: #0555FA;
    position: absolute;
    content: '';
    left: 0;
    /*top: 50%;*/
    top: 40%;
    transform: translate(0, -50%);
  }
  .inside_step h1{
    font-size: 25px;
    padding-left: 20px;
  }
  .inside_step p.sub-title{
    margin-top: 30px;
  }
  .coordone_wrapper{
    padding: 0 20px;
  }
  .wrapper-input{
    width: 100%;
  }
  .dropdown-type-file, .input_upload_selected_file{
    max-width: 284px;
  }
  .wrapper-uploaded-files{
    margin-top: 15px;
  }
  .wrapper-files-upload{
    margin-top: 40px;
  }
  .wrapper-files-upload:before{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: -20px;
    height: 1px;
    width: 100%;
    background: #C2C8D2;
  }
  .add-file-btn{
    margin-top: 40px !important;
  }
}

.apply-hide{
  overflow: hidden;
  padding-bottom: 116px; /* added for bandeau */
}
b, strong{
  font-family: "RoobertEnel-Bold";
}
@media screen and (max-width: 992px){
  .modal-90w{
    max-width: none !important;
    width: 90%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px){
  .pop_up .pop_up_container{
    padding: 45px;
  }
}
@media screen and (max-width: 576px){
  .simple_input_phone, .simple_input{
    max-width: none;
    width: 100%;
  }
  .input-rappel{
    margin-bottom: 30px;
  }
  .dropdown-postal{
    width: 100%;
  }
  .mobile-close-btn{
    background: #E90657;
    padding: 10px;
    border-radius: 50%;
  }
  .only-mobile-bloc span{
    font-family: "RoobertEnel-Regular";
    font-size: 11px;
  }
}
@media screen and (max-width: 632px){
  .conso_width{
    margin-left: 0;
    display: block;
    margin: 20px auto 0;
  }
}
@media screen and (max-width: 420px){
  .pop_up .pop_up_container{
    padding: 45px 20px;
  }
}
@media screen and (max-width: 992px){
  .rpv-core-display-hidden{
    display: block;
  }
}
.rpv-toolbar-center{
  justify-content: start;
}
.rpv-toolbar-left,
.rpv-default-layout-sidebar,
.rpv-open-button,
.rpv-toolbar-right >.rpv-toolbar-item {
  display: none;
}
.rpv-toolbar-right .rpv-core-display-hidden:nth-last-child(-n+2){
  display: none;
}
.modal-content{
  border-radius: 10px !important;
  border: 1px solid #0555FA !important;
}
.rpv-default-layout-toolbar{
  background: transparent !important;
}
.modal-backdrop{
  background: #fff !important;
}
.modal-backdrop.show {
  opacity: 0.9 !important;
}
.close-btn-brochure{
  text-align: right;
}
.close-btn-brochure img{
  margin: 10px;
  cursor: pointer;
}
.link_portail{
  display: inline-block !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  background: transparent !important;
  width: auto !important;
}
textarea.simple_input {
  resize: none;
  height: 150px;
  outline: none;
}
.pink-text{
  color: #E90657;
}
/* Chrome */
input.txt_c_p::-webkit-outer-spin-button,
input.txt_c_p::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.txt_c_p[type=number] {
  -moz-appearance: textfield;
}