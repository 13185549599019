.footer{
    float:left;
    display: block;
    width:100%;
    height:145px;
    padding-top: 90px;
    background:#ffffff;
}
.footer a,.footer p{
    /*font-family: "Univers";*/
    font-family: "RoobertEnel-Regular";
    color:#667790;
    font-size:13px;
}
.copyright{
    padding-left: 11%;
}
.footer a{
    text-align: center;
}
.links-footer{
    padding: 0;
    margin: 0;
    text-align: right;
    display: block;
}
.links-footer li{
    list-style: none;
    display: inline-block;
    margin-right: 20px;
}
@media screen and (max-width: 576px){
    .links-footer{
        text-align: center;
    }
    .footer{
        padding-top: 45px;
        padding-bottom: 15px;
        height: auto;
    }
    .footer .copyright{
        padding-left: 0;
        text-align: center;
        margin-bottom: 60px;
        font-size: 18px;
    }
}