.bandeauParent{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #EFF2F7;
    z-index: 80;
}
.bandeauParent .bandeauWrapper{
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bandeauParent .bandeauWrapper p{
    font-family: "RoobertEnel-Regular";
    margin-right: 10px;
}
.bandeauParent .bandeauWrapper a{
    float: right;
    width: 165px;
    height: 40px;
    line-height: 50px;
    color: #E90657;
    background: #ffffff url('../../assets/img/phone_aide.svg') no-repeat 80% center;
    padding: 0 15px;
    border: 1px solid #E90657;
    outline: none;
    border-radius: 5px;
    text-align: left;
    font-family: "RoobertEnel-Regular";
    padding-left: 33px;
    align-items: center;
    display: flex;
}
.bandeauParent .bandeauWrapper a.rappelIcone{
    background: #ffffff url('../../assets/img/phone_aide.svg') no-repeat 85% center;
    padding-left: 25px;
}
@media screen and (max-width: 576px){
    .bandeauParent .bandeauWrapper{
        flex-direction: column;
    }
    .bandeauParent .bandeauWrapper p{
        text-align: center;
    }
}
.grecaptcha-badge{
    display: none;
}