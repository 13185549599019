/*
.sites_container .sep_site{
    background:#C2C8D2;
    height:1px;
    width:100%;
    display: block;
    margin:0 auto 15px;
}
*/
.input_invalid {
    border: 1px solid #dc3545 !important;
}
.infot-text-conso{
    max-width: 100% !important;
}
.sites_container{
    border-top: 1px solid #C2C8D2;
    padding-top: 15px;
}
.border-conso{
    position: relative;
}
.border-conso:before{
    content: '';
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #C2C8D2;
}
.sites_container .site_container h2{
    /*font-family: "UniversLTPro-45Light";*/
    font-family: "RoobertEnel-Light";
    font-size:25px;
    color:#0555FA;
    margin: 15px 0 0;
    padding: 0;
}
.sites_container .site_container h3{
    padding: 0;
    font-size:16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    color:#667790;
    margin: 0 0 4px;
}
.sites_container .site_container p{
    margin: 0;
    padding: 0;
    font-size:16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    color:#0F141A;
}
.sites_container .site_container .type-details p{
    margin: 10px 0px;
    padding: 0;
    font-size:16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    color:#0F141A;
}
.sites_container .site_container .btn_edit_site,
.sites_container .site_container .btn_edit_site:active,
.sites_container .site_container .btn_edit_site:focus,
.sites_container .site_container .btn_edit_site:visited{
    border-radius: 5px;
    border:1px solid #0555FA;
    color:#667790;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    text-align: left;
    background:#ffffff url('../../assets/img/draw.svg') no-repeat 90% center;
    background-size: 25px 50px;
    width:216px;
    height:50px;
    line-height: 45px;
    padding: 0 0 0 13px;
    outline: none;
}
.sites_container .site_container .btn_edit_site:hover{
    background:#0555FA;
    text-align: center;
    color:#ffffff;
    padding: 0;
    transition: 250ms ease-in-out all;
    outline: none;
}
.sites_container .site_container .pce_input,
.sites_container .site_container .pce_input:focus{
    width:280px;
    height:50px;
    line-height: 45px;
    padding:0 5px;
    border-radius:5px;
    border:1px solid #667790;
    color:#0555FA;
    font-size:16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.sites_container .site_container .validate_pce,
.sites_container .site_container .validate_pce:active,
.sites_container .site_container .validate_pce:visited,
.sites_container .site_container .validate_pce:focus{
    border-radius: 5px;
    border:1px solid #0555FA;
    color:#667790;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    text-align: center;
    text-transform: uppercase;
    font-size:11px;
    background:#ffffff;
    width:118px;
    height:50px;
    line-height: 49px;
    margin: 0 0 0 15px;
    outline: none;
}
.sites_container .site_container .validate_pce:disabled{
    opacity: .5;
}
.sites_container .site_container .validate_pce:hover{
    background:#0555FA;
    color:#ffffff;
    padding: 0;
    transition: 250ms ease-in-out all;
}
.sites_container .site_container .validate_pce:hover:disabled{
    background:#ffffff;
    border:1px solid #0555FA;
    color:#667790;
}
.sites_container .site_container .no_facture{
    padding:0 10px 2px;
    border-bottom:1px solid #0555FA;
    cursor: pointer;
    display: inline;
}
.sites_container .site_container .date_input_pce{
    border-radius: 5px;
    width:175px;
    height:50px;
    line-height: 45px;
    padding:0 25px 0 0;
    border:1px solid #667790;
    color:#667790;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    text-align: right;
    font-size:17px;
    background:#ffffff url('../../assets/img/calendrier.svg') 14px center no-repeat;
    background-size: 30px 50px;

}
input.valider:disabled {
    opacity: .5;
}
.type{
    padding: 10px ;
    text-align:center;
    background-color: #0555FA;
    border-radius: 10px;
    display: inline-block;
    margin-bottom: 15px;
}
.btn-show-pce{
  display: block !important;
  background-color: #0555FA !important;
  max-width: 150px !important;
  color: #fff !important;
  font-size: 12px !important;
  font-family: "RoobertEnel-Regular";
}
/* MOBILE CSS */
@media screen and (max-width: 576px){
    .sites_container .site_container .validate_pce,
    .sites_container .site_container .validate_pce:active,
    .sites_container .site_container .validate_pce:visited,
    .sites_container .site_container .validate_pce:focus{
        max-width: 94px;
        width: 100%;
    }
    .sites_container .site_container .pce_input{
        width: calc(100% - 110px) !important;
    }
}
