.pop_up2{
    /*position:absolute;*/
    /*position: fixed;*/
    /*left:0;*/
    /*top:0;*/
    /*bottom:0;*/
    /*right:0;*/
    /*height: 100%;*/
    /*z-index: 99;*/
    /*height: 150%;*/
    /*width: 100vw;*/
}
.pop_up2 .bg_pop_up2{
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    z-index: 100;
    background:url('../../assets/img/bg_white.png');
}
.pop_up2 .pop_up2_container{
    /*position: absolute;
    left:18%;
    right: 18%;
    top:10%;
    bottom:20%;*/
    border-radius: 5px;
    border:1px solid #0555FA;
    z-index: 101;
    padding:50px 65px 10px;
    /*height: fit-content;*/
    background:#ffffff;
}
.close_facture{
    position: absolute;
    right:40px;
    top:40px;
    color: #0555FA;
    font-size:45px;
    font-weight: lighter;
    cursor: pointer;
}
button.btn-close{
    background: none;
    border: none;
    position: absolute;
    top: 30px;
    right: 30px;
}
button.btn-download{
    background: none;
    border: none;
    position: absolute;
    top: 30px;
    left: 30px;
}
.Document canvas{
    width: 100%!important ;
    height: 100%!important ;
}
.PageButtons{
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PageButtons button{
    margin: 0px 10px;
    width:25px;
    height:25px;
}
.PageButtons .Right{
    background:#ffffff url('../../assets/img/right-arrow.svg') center no-repeat;
    background-size: 50% 50% ;
}
.PageButtons .Left{
    background:#ffffff url('../../assets/img/left-arrow.svg') center no-repeat;
    background-size: 50% 50% ;
}
.PageButtons p,.react-pdf__message--no-data{
    color: #0F141A;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    text-align: center;
}
@media (max-width: 650px ){
    .pop_up2 .pop_up2_container{
        width: 100%;
        max-width: calc(100% - 40px);
        padding: 75px 15px;
        margin: 0 auto;
        left: 0%;
        right: 0%;
        top: 5%;
    }
    button.btn-close{
        background: none;
        border: none;
        position: absolute;
        top: 10px !important;
        right: 10px !important;
    }
    button.btn-download{
        background: none;
        border: none;
        position: absolute;
        top: 10px;
        left: 10px;
    }
}