.site_souscription{
    padding-bottom:20px;
    border-bottom:1px solid #C2C8D2;
}
.site_souscription h4{
    color:#0555FA;
    /*font-family: "UniversLTPro-45Light";*/
    font-family: "RoobertEnel-Light";
    font-size:25px;
}
.site_souscription input[type="text"]{
    width:60%;
    display: inline-block;
}
.site_souscription .info_addr_four,
.client .info_postal,
.site_souscription .info_addr_envoi,
.site_souscription .info_addr_facturation,
.facture .info,
.divergent-infos .info,
.rib_info,
.infos-phone-divergent,
.wrapper-uploaded-files .info_files{
    color:#0555FA;
    background: #fff;
    /*font-family: "Univers";*/
    font-family: "RoobertEnel-Regular";
    font-size:14px;
    width:26px;
    height: 26px;
    border:1px solid #C2C8D2;
    text-align: center;
    border-radius: 50%;
    font-weight: normal;
    line-height: 26px;
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.link-contact{
    color: #E90657;
    text-decoration: underline;
    font-weight: bold;
}
.link-contact:hover{
    color: #E90657;
}
.site_souscription .info_addr_four:hover,
.client .info_postal:hover,
.client .info_postal:active,
.client .info_postal.hovered,
.site_souscription .info_addr_envoi:hover,
.site_souscription .info_addr_facturation:hover,
.facture .info:hover,
.divergent-infos .info:hover {
    color: #fff;
    background: #E90657;
    border:1px solid #E90657;
}
.check_2{
    cursor: pointer;
}
.check_2 input[type="checkbox"]{
    display: none;
}
.check_2.active p{
    color:#0555FA;
}
.check_2 img{
    float: left;
    margin-right: 10px;
    clear: both;
}
.check_2 p{
    font-size:14px;
    color:#667790;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    display: inline-block;
    margin:0;
}
.btn_edit_email,.btn_resend_mail{
    font-size:16px;
    color:#667790;
    cursor: pointer;
    text-align: left;
    border: none;
    outline: none;
    border-bottom:1px solid #0555FA;
    background:none;    
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    padding:3px 10px 3px 0;
}
.btn_edit_email:focus,.btn_resend_mail:focus{
    border:none;
    color:#0555FA;
    border-bottom:1px solid #0555FA;
}
.btn_appliquer,.btn_appliquer:active,.btn_appliquer:visited{
    color:#667790;
    font-size:11px;
    text-transform: uppercase;
    /*font-family: "UniversLTPro-65Bold";*/
    font-family: "RoobertEnel-Bold";
    text-align: center;
    border:1px solid #0555FA;
    background: none;
    width:118px;
    height: 48px;
    line-height: 46px;
    border-radius: 5px;
    margin: 0 0 0 15px;
}
.h1{
    font-size:48px;
    /*font-family: "UniversLTPro-45Light";*/
    font-family: "RoobertEnel-Light";
    color:#0F141A;
    margin:15px 0;
}
.btn_equipe{
    display: block;
    margin:40px 0;
    /*font-family: "UniversLTPro-45Light";*/
    font-family: "RoobertEnel-Light" !important;
    text-align: center;
    color:#0F141A !important;
    font-size:20px !important;
}
.confimation-pop{
    /*text-align: center;*/
}
.txt_welcome{
    font-size:16px;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.btn_end,.btn_end:visited,.btn_end:active{
    width:203px;
    height:56px;
    line-height: 50px;
    color:#ffffff;
    background:#E90657 url('../../assets/img/to_right.png') no-repeat 90% center;
    padding:0 15px;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: left;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.btn_redirect,.btn_redirect:visited,.btn_redirect:active{
    color:#ffffff;
    background:#E90657 url('../../assets/img/to_right.png') no-repeat 90% center;
    padding: 20px 45px;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: left;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    text-decoration: none !important;
}

form.souscription-form .rappel_label{
    font-size: 14px;
    color: #667790;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
    display: inline-block;
    margin: 0;
}
form.souscription-form .rappel_label input{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
form.souscription-form .rappel_label input:checked{
    background: url('../../assets/img/check_2_active.png');
    appearance: none;
    outline: none;
}
form .react-tel-input{
    display: inline-block;
    width: 75%;
}
input.btn_souscription:disabled {
    background: #dddddd;
}
input.btn_next_continue:disabled {
    opacity: .5;
}
.own_tooltip{
    background: #EFF2F7 !important;
    color: #0555FA !important;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular" !important;
    z-index: 1001;
}
.own_tooltip:after{
    border-right-color: #EFF2F7 !important;
}
.simple_input_phone{
    width: 280px;
    height: 50px;
    font-size: 16px;
    color: #667790;
    padding: 7px 0px 5px 55px;
    border: 1px solid #C2C8D2;
    border-radius: 5px;
    box-shadow: 0 0 6px -2px #cccccc;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}
.small-text-help{
    font-size: 12px !important;
    color: #667790 !important;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular" !important;
}
.hide-me{
    display: none;
}
.loader-text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #0555FA;
    /*font-family: "UniversLTPro-55Roman";*/
    font-family: "RoobertEnel-Regular";
}

@media screen and (max-width: 576px){
    form .react-tel-input{
        width: 90%;
    }
}
@media screen and (max-width: 767px){
    .souscription-form .marg_top_input{
        margin-top: 3rem;
    }
    .souscription-form .site_souscription input[type="text"]{
        width: 90%;
        font-size: 14px;
    }
    .souscription-form .check_2 p{
        display: block;
    }
    .btn_appliquer, .btn_appliquer:active, .btn_appliquer:visited{
        width: 100px;
    }
    .frm_code_validation .simple_input{
        width: calc(100% - 115px);
    }
}
@media screen and (max-width: 320px){
    .souscription-form .simple_input_phone{
        width: 260px;
    }
    .souscription-form .site_souscription input[type="text"]{
        width: calc(100% - 34px)
    }
}
@media screen and (max-width: 1200px) {
    .simple_input_phone, .simple_input{
        max-width: 230px;
        width: 90%;
    }
}